import { Photo } from './Photo';
import { Video } from './Video';
import { Model } from './Model';

export enum BlockType {
  ADVERTISEMENT = 'advertisement',
  MODEL = 'model',
  PHOTO = 'photo',
  VIDEO = 'video',
}

export interface BlockAdvertisement {
  id: number
  block_id: number
  image: string
  image_link: string
  link: string
  mobile_image: string
  mobile_image_link: string
  updated_at: string
  created_at: string
}

export type BlockVideo = Video & { sort: number };
export type BlockModel = Model & { sort: number };

export interface Block {
  id: number
  title: string
  description: string
  type: BlockType
  sort: number
  advertisement: BlockAdvertisement
  photos: Photo[]
  videos: BlockVideo[]
  models: BlockModel[]
  photo_ids: number[]
  video_ids: number[]
  model_ids: number[]
}

export type PartialBlockAdvertisement = Partial<BlockAdvertisement>;
export type PartialBlock = Partial<Block>;
